import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { StepBar } from 'components'

import { useToast } from 'hooks/toast'
import { StepComponentProps } from 'hooks/FluxManagement/types'
import useStackFluxManagement from 'hooks/FluxManagement/useStackFluxManagement'
import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'

import {
  Steps,
  IFormConsortium,
  CODIGO_PRODUTO,
} from 'pages/Consortium/SolicitationProcess/Types'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { IProposalConsortiumState } from 'store/modules/Consortium/Proposal/types'
import { FormProposalConsortium } from 'pages/Consortium/SolicitationProcess/Types'

import { PostSendProposalRequest } from 'store/modules/Consortium/SendProposal/action'
import { ISendProposalConsortiumState } from 'store/modules/Consortium/SendProposal/types'

import PepData from '../cards/PepData'
import ParcelData from '../cards/ParcelData'
import AddressData from '../cards/AddressData'
import ContactData from '../cards/ContactData'
import PaymentData from '../cards/PaymentData'
import DocumentData from '../cards/DocumentData'
import PersonalData from '../cards/PersonalData'
import FinancialCard from '../cards/FinancialCard'
import ConsortiumData from '../cards/ConsortiumData'
import LifeInsuranceData from '../cards/LifeInsuranceData'

import {
  validatePersonalFom,
  validateDocumentForm,
  validateFinancialForm,
  validatePaymentForm,
} from './utils'
import { IsValidFormType } from './types'
import * as s from '../styles'

const SummaryProposal: React.FC = () => {
  const { hire } = TextsConsortium()

  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast, removeToast, toasts } = useToast()
  const { instantiate } = useStackFluxManagement<StepComponentProps>()
  const { updateForm } = useFormFluxManagement<IFormConsortium>()

  const [proposalData, setProposalData] = React.useState<
    FormProposalConsortium
  >()

  const [submitCount, setSubmitCount] = React.useState<number>(0)

  const [isValidForm, setIsValidForm] = React.useState<IsValidFormType>({
    personalForm: false,
    documentForm: false,
    financialForm: false,
    paymentForm: false,
    lifeInsuranceForm: false,
  })

  const { data } = useSelector(
    (state: { consortiumProposal: IProposalConsortiumState }) =>
      state.consortiumProposal
  )

  const {
    isLoading: isSubmitLoading,
    data: sendProposalData,
    hasError,
  } = useSelector(
    (state: { consortiumSendProposal: ISendProposalConsortiumState }) =>
      state.consortiumSendProposal
  )

  React.useEffect(() => {
    if (data && !proposalData) {
      setProposalData({
        ...data,
        codigo_canal: '1',
        proponente: { ...data.proponente },
      })

      setIsValidForm({
        ...isValidForm,
        personalForm: validatePersonalFom(data),
        documentForm: validateDocumentForm(data),
        financialForm: validateFinancialForm(data),
        paymentForm: validatePaymentForm(data),
      })
    }
  }, [setProposalData, data, isValidForm, proposalData])

  React.useEffect(() => {
    if (!isSubmitLoading && toasts && toasts.length > 0) {
      setTimeout(() => {
        removeToast(toasts[0].id)
      }, 1500)
    }
  }, [isSubmitLoading, removeToast, toasts])

  React.useEffect(() => {
    if (sendProposalData) {
      if (proposalData) {
        updateForm({
          simulation: {
            codigo_canal: Number(proposalData.codigo_canal),
            codigo_produto: CODIGO_PRODUTO.IMOVEL,
            tipo_busca: Number(proposalData.tipo_grupo),
            tipo_pessoa: 0,
            uf: '',
            valor_credito: proposalData?.valor_credito,
          },
          proposal: proposalData,
        })
      }

      instantiate(Steps.REQUEST_RESULT)
    }

    if (hasError) {
      addToast({
        type: 'error',
        title: 'Erro ao enviar solicitação. ',
        description: 'Por favor, tente novamente.',
      })
    }

    if (submitCount === 3 && hasError) {
      if (proposalData) {
        updateForm({
          simulation: {
            codigo_canal: Number(proposalData.codigo_canal),
            codigo_produto: CODIGO_PRODUTO.IMOVEL,
            tipo_busca: Number(proposalData.tipo_grupo),
            tipo_pessoa: 0,
            uf: '',
            valor_credito: proposalData?.valor_credito,
          },
          proposal: proposalData,
        })
      }

      instantiate(Steps.REQUEST_RESULT)
    }
  }, [
    submitCount,
    hasError,
    proposalData,
    isSubmitLoading,
    instantiate,
    sendProposalData,
    updateForm,
    addToast,
  ])

  const checkIsValidForm = React.useCallback(() => {
    if (proposalData) {
      return (
        !isValidForm.documentForm ||
        !isValidForm.financialForm ||
        !isValidForm.lifeInsuranceForm ||
        !isValidForm.paymentForm ||
        !isValidForm.personalForm
      )
    }

    return true
  }, [isValidForm, proposalData])

  const handleClick = React.useCallback(() => {
    if (proposalData) {
      dispatch(PostSendProposalRequest({ ...proposalData, codigo_canal: '2' }))
      setSubmitCount(submitCount + 1)
    }
  }, [proposalData, submitCount, dispatch, setSubmitCount])

  if (proposalData) {
    return (
      <>
        <StepBar
          title="Contratação"
          type="Consórcio de Imóvel"
          showSteps={false}
        />

        <s.ContentContainer>
          <s.ColumnSide lg={3} sm={12}>
            <s.TitleWrapper>
              <s.SideTitle>
                {hire.steps.summary.pro.containerSide.title}
              </s.SideTitle>
              <s.TextSide>
                {hire.steps.summary.pro.containerSide.subtitle}
              </s.TextSide>
            </s.TitleWrapper>
          </s.ColumnSide>
          <s.Column lg={8} sm={12}>
            {/* Dados pessoais */}
            <PersonalData
              proposalData={[proposalData, setProposalData]}
              isValidForm={[isValidForm, setIsValidForm]}
            />

            {/* Dados para contato */}
            <ContactData proposalData={[proposalData, setProposalData]} />

            {/* Endereço */}
            <AddressData proposalData={[proposalData, setProposalData]} />

            {/* Documentos */}
            <DocumentData
              proposalData={[proposalData, setProposalData]}
              isValidForm={[isValidForm, setIsValidForm]}
            />

            {/* PEP */}
            <PepData proposalData={[proposalData, setProposalData]} />

            {/* Dados Financeiros */}
            <FinancialCard
              proposalData={[proposalData, setProposalData]}
              isValidForm={[isValidForm, setIsValidForm]}
            />

            {/* Dados do Consórcio */}
            <ConsortiumData proposalData={[proposalData, setProposalData]} />

            {/* Dados da Parcela */}
            <ParcelData proposalData={[proposalData, setProposalData]} />

            {/* Dados de pagamento */}
            <PaymentData
              proposalData={[proposalData, setProposalData]}
              isValidForm={[isValidForm, setIsValidForm]}
            />

            {/* Seguro de vida | Seguro Prestamista */}
            <LifeInsuranceData
              proposalData={[proposalData, setProposalData]}
              isValidForm={[isValidForm, setIsValidForm]}
            />
          </s.Column>
        </s.ContentContainer>
        <s.FooterContainer>
          <s.ContentWrapperFooter>
            <s.RejectButton color="secondary" onClick={() => history.push('/')}>
              rejeitar proposta
            </s.RejectButton>
            <s.SubmitButton
              disabled={isSubmitLoading ? false : checkIsValidForm()}
              color="secondary"
              onClick={handleClick}
              loading={isSubmitLoading}
              width="195px"
            >
              {submitCount > 0 ? 'Tentar novamente' : 'contratar'}
            </s.SubmitButton>
          </s.ContentWrapperFooter>
        </s.FooterContainer>
      </>
    )
  }

  return <> </>
}

export default SummaryProposal
