import React from 'react'
import { TOptions, TSelected } from 'components/Inputs/Select/types'
import { capitalize } from 'helpers'
import { IBank } from 'store/modules/Consortium/Bank/types'
import { IPaymentTerms } from 'store/modules/Consortium/PaymentTerms/types'

export const formatStringBank = (bank: string): string =>
  bank
    .split(' ')
    .map(value => {
      if (value === 'S/A') {
        return value
      }

      return capitalize(value)
    })
    .join(' ')

export const zeroLeftString = (code: string): string => {
  switch (code.length) {
    case 1:
      return `000${code}`
    case 2:
      return `00${code}`
    case 3:
      return `0${code}`
    default:
      return code
  }
}

export const getDataBank = (
  banks: IBank[],
  setSelect: React.Dispatch<React.SetStateAction<TSelected>>,
  cb: (bank: string, code: string) => void,
  updateForm: (key: string, value: string | IPaymentTerms) => void
): TOptions[] => {
  if (banks && banks.length) {
    return banks.map((value, id) => ({
      id,
      text: `${zeroLeftString(value.codigo)} - ${formatStringBank(
        value.descricao
      )}`,
      onClick: () => {
        updateForm(
          'nome_banco',
          `${zeroLeftString(value.codigo)} - ${formatStringBank(
            value.descricao
          )}`
        )
        setSelect({
          id,
          name: `${zeroLeftString(value.codigo)} - ${formatStringBank(
            value.descricao
          )}`,
        })
        cb(value.descricao.toUpperCase(), value.codigo)
      },
    }))
  }

  return []
}
