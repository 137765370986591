import React, { useLayoutEffect, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { StepBar, ButtonLink, ModalNew } from 'components'

import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'

import { IFormConsortium } from 'pages/Consortium/SolicitationProcess/Types'
import DrawerChangePayment from 'pages/Consortium/SolicitationProcess/HireSteps/PaymentDrawer'

import { ApplicationState } from 'store/ApplicationState'
import { PostSendProposalRequest } from 'store/modules/Consortium/SendProposal/action'
import { IPaymentTermsInstallment } from 'store/modules/Consortium/PaymentTerms/types'

import moment from 'moment'

import { useDrawer, useModal, useErrorFeedback } from './hooks'

import { PageTip, Footer, TextsConsortium } from 'pages/Consortium/commons'

import { formatMoney } from 'helpers/index'

import { ModalContent, SummarySection } from './localComponents'
import { SummaryItem } from './localComponents/SummarySection/models'
import { converteUFToState } from '../StepOne/PersonalDataForm/utils'
import { summaryMock } from './mocks'
import { stepBar, page, footer, modal } from './static'
import * as s from './styles'
interface ISummary {
  consortium: {
    items: Array<SummaryItem>
  }
  portion: {
    items: Array<SummaryItem>
  }
  payment: {
    items: Array<SummaryItem>
  }
}

const Summary = () => {
  const dispatch = useDispatch()
  const { form } = useFormFluxManagement<IFormConsortium>()
  const { isDrawerOpen, toggleDrawer } = useDrawer()
  const { resetSimulation, isModalOpen, toggleModal } = useModal()
  const [formData, setFormData] = useState<ISummary | null>(null)
  const {
    hire: {
      steps: {
        summary: { containerFirst, containerSecond, containerThird },
      },
    },
  } = TextsConsortium()

  const [numberOfSends, setNumberOfSends] = useState(0)

  const { data, isLoading, hasError } = useSelector(
    (state: ApplicationState) => state.consortiumSendProposal
  )

  useErrorFeedback(data, isLoading, hasError, numberOfSends)

  useLayoutEffect(() => {
    const layerChildrenElement = document.querySelector('#layer-children')
    if (layerChildrenElement) {
      layerChildrenElement.scrollTo({
        top: 0,
      })
    }
  }, [])

  const handleSubmit = () => {
    setNumberOfSends(numberOfSends + 1)
    dispatch(
      PostSendProposalRequest({
        ...form.proposal,
        proponente: {
          ...form.proposal.proponente,
          cpf: form.proposal.proponente.cpf,
          endereco_residencial: {
            ...form.proposal.proponente.endereco_residencial,
            uf: converteUFToState(
              form.proposal.proponente.endereco_residencial.uf
            ),
          },
        },
      })
    )
  }

  const formatParcela = (parcelas: IPaymentTermsInstallment[]) => {
    return parcelas.map((value: IPaymentTermsInstallment) => ({
      label: value.descricao,
      value: formatMoney(value.valor),
    }))
  }

  useEffect(() => {
    const AGENCY = `${form.proposal.proponente.dados_bancarios.numero_agencia}`
    const ACCOUNT_NUMBER = `${form.proposal.proponente.dados_bancarios.numero_conta}-${form.proposal.proponente.dados_bancarios.digito_conta}`
    const SEGURO_VIDA =
      Number(form.proposal.seguro_vida) *
      (Number(form.proposal.valor_credito) / 100)

    const minimumDigitsHandler = (number: number, targetLength: number) => {
      const NUM = number
      const LENGTH = targetLength
      let output = NUM + ''

      while (output.length < LENGTH) {
        output = '0' + output
      }

      return `${output}`
    }

    const BANK_NAME = `${minimumDigitsHandler(
      Number(form.proposal.proponente.dados_bancarios.codigo_banco),
      3
    )} – ${form.proposal.proponente.dados_bancarios.nome_banco}`

    setFormData({
      consortium: {
        items: [
          {
            label: containerFirst.creditValue,
            value: formatMoney(`${form.proposal.valor_credito}`),
          },
          {
            label: containerFirst.reserveFund,
            value: `${form.proposal.fundo_reserva}%`.replace('.', ','),
          },
          {
            label: containerFirst.lifeInsurance,
            value: formatMoney(`${SEGURO_VIDA}`),
          },
          {
            label: containerFirst.administrativeFree,
            value: `${form.proposal.taxa_administrativa}%`,
          },
          {
            label: containerFirst.earlyAdministrationFree,
            value: `2%`,
          },
        ],
      },
      portion: {
        items: [
          {
            label: containerSecond.monthlyInstallment,
            value: formatMoney(`${form.proposal.valor_demais_parcelas}`),
          },
          {
            label: containerSecond.numberInstallment,
            value: `${form.proposal.prazo}`,
          },
          {
            label: containerSecond.dateForeseenFirstInstallment,
            value: String(
              moment(form.proposal.dt_venc_assembl, 'DD/MM/YYYY').format(
                'DD/MM/YYYY'
              )
            ),
          },
        ],
      },
      payment: {
        items: [
          {
            label: containerThird.firstInstallmentPaymentMethod,
            value: `${
              form.proposal.forma_pagamento_adesao === 'BOLETO_BANCARIO'
                ? 'Boleto'
                : 'Débito automático'
            }`,
          },
          {
            label: containerThird.paymentRemainingInstallments,
            value: 'Débito automático em conta corrente',
          },
          {
            label: containerThird.bank,
            value: BANK_NAME,
          },
          {
            label: containerThird.agency,
            value: AGENCY,
          },
          {
            label: containerThird.currentAccount,
            value: ACCOUNT_NUMBER,
          },
          {
            label: containerThird.earlyAdministrationFree,
            value: form.proposal.condicao_pagamento_adesao.descricao || '0',
          },
          ...formatParcela(form.proposal.condicao_pagamento_adesao.parcelas),
        ],
      },
    })
  }, [
    form,
    containerFirst.administrativeFree,
    containerFirst.creditValue,
    containerFirst.lifeInsurance,
    containerFirst.reserveFund,
    containerFirst.earlyAdministrationFree,
    containerSecond.dateForeseenFirstInstallment,
    containerSecond.monthlyInstallment,
    containerSecond.numberInstallment,
    containerThird.agency,
    containerThird.bank,
    containerThird.currentAccount,
    containerThird.firstInstallmentPaymentMethod,
    containerThird.paymentRemainingInstallments,
    containerThird.earlyAdministrationFree,
  ])

  return (
    <>
      <StepBar
        title={stepBar.title}
        type={stepBar.type}
        actualStep={stepBar.currentStep}
        totalNumberOfSteps={stepBar.numberOfSteps}
      />
      <s.ContentContainer>
        <s.ColumnSide lg={3} md={10}>
          <PageTip title={page.title} subtitle={page.subtitle} />
        </s.ColumnSide>
        <s.Column lg={8} md={10}>
          <s.SummaryContent>
            <SummarySection
              title={summaryMock.consortium.title}
              items={formData ? formData.consortium.items : []}
              button={
                <ButtonLink onClick={toggleModal} data-testid="reset-button">
                  <s.ButtonLinkTitle>
                    {page.resetButtonLink.title}
                  </s.ButtonLinkTitle>
                </ButtonLink>
              }
            />
            <SummarySection
              title={summaryMock.portion.title}
              items={formData ? formData.portion.items : []}
            />
            <SummarySection
              title={summaryMock.payment.title}
              items={formData ? formData.payment.items : []}
              button={
                <ButtonLink onClick={toggleDrawer} data-testid="change-button">
                  <s.ButtonLinkTitle>
                    {page.changeButtonLink.title}
                  </s.ButtonLinkTitle>
                </ButtonLink>
              }
            />
          </s.SummaryContent>
        </s.Column>
      </s.ContentContainer>
      <Footer>
        {/* onClick -> Necessário resolver pendências anteriores. */}
        <s.Button loading={isLoading} onClick={handleSubmit}>
          {footer.button[hasError ? 'titleFail' : 'title']}
        </s.Button>
      </Footer>
      <DrawerChangePayment
        isOpen={isDrawerOpen}
        handleClose={toggleDrawer}
        data-testid="drawer"
      />
      <ModalNew
        data-testid="modal"
        modalTitle={modal.title}
        isOpen={isModalOpen}
        handleClose={toggleModal}
      >
        <ModalContent
          description={modal.description}
          resetButton={{
            title: modal.resetButton.title,
            onClick: resetSimulation,
          }}
          continueButton={{
            title: modal.continueButton.title,
            onClick: toggleModal,
          }}
        />
      </ModalNew>
    </>
  )
}

export default Summary
