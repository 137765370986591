import { GetBankRequest } from 'store/modules/Consortium/Bank/action'
import { GetJobsRequest } from 'store/modules/Consortium/Jobs/action'
import { GetFatcaRequest } from 'store/modules/Consortium/Fatca/action'
import { GetTypesRequest } from 'store/modules/Consortium/PpeTypes/action'
import { GetProposalRequest } from 'store/modules/Consortium/Proposal/action'
import { GetCountriesRequest } from 'store/modules/Consortium/Countries/action'
import { GetDocumentsRequest } from 'store/modules/Consortium/Documents/action'
import { GetCivilStatusRequest } from 'store/modules/Consortium/CivilStatus/action'
import { GetPaymentTermsRequest } from 'store/modules/Consortium/PaymentTerms/action'
import { GetPepRelationshipRequest } from 'store/modules/Consortium/PpeRelations/action'
import { GetPassportCountriesRequest } from 'store/modules/Consortium/PassportCountries/action'

import { LoadStepType } from '../../Components/LoadData/types'
import {
  FormProposalConsortium,
  CODIGO_PRODUTO,
} from 'pages/Consortium/SolicitationProcess/Types'

const GetPaymentTerms = (
  dispatch: React.Dispatch<any>,
  form: FormProposalConsortium
) => {
  dispatch(
    GetPaymentTermsRequest({
      codigo_canal: Number(form.codigo_canal),
      codigo_produto: CODIGO_PRODUTO.IMOVEL,
      codigo_grupo: form.grupo,
      numero_contrato: String(form.numero_sequencia_proposta),
      valor_credito: form.valor_credito,
    })
  )

  return null
}

const GetProposal = (dispatch: React.Dispatch<any>) => {
  const hash = localStorage.getItem('redisKey')

  dispatch(
    GetProposalRequest({
      payload: hash || '',
    })
  )

  return null
}

export const fluxLoading: LoadStepType[] = [
  {
    method: GetProposal,
    selector: 'consortiumProposal',
    callBack: (
      data: any,
      form: FormProposalConsortium,
      updateForm: (updatedKeys: FormProposalConsortium) => void
    ) => {
      updateForm({ ...data, codigo_produto: CODIGO_PRODUTO.IMOVEL })
    },
  },
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetJobsRequest()),
    selector: 'consortiumJobs',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) =>
      dispatch(GetCivilStatusRequest()),
    selector: 'consortiumCivilStatus',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) =>
      dispatch(GetPepRelationshipRequest()),
    selector: 'consortiumPpeRelations',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetTypesRequest()),
    selector: 'consortiumPpeType',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) =>
      dispatch(GetPassportCountriesRequest()),
    selector: 'consortiumPassportCountries',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetFatcaRequest()),
    selector: 'consortiumFatca',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetCountriesRequest()),
    selector: 'consortiumCountries',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetDocumentsRequest()),
    selector: 'consortiumDocuments',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetBankRequest()),
    selector: 'consortiumBank',
    callBack: () => {},
  },
  {
    method: GetPaymentTerms,
    selector: 'consortiumPaymentTerms',
    callBack: () => {},
  },
]
