import React from 'react'

import { GetBankRequest } from 'store/modules/Consortium/Bank/action'
import { GetJobsRequest } from 'store/modules/Consortium/Jobs/action'
import { GetFatcaRequest } from 'store/modules/Consortium/Fatca/action'
import { GetTypesRequest } from 'store/modules/Consortium/PpeTypes/action'
import { GetCountriesRequest } from 'store/modules/Consortium/Countries/action'
import { GetDocumentsRequest } from 'store/modules/Consortium/Documents/action'
import { GetCivilStatusRequest } from 'store/modules/Consortium/CivilStatus/action'
import { GetPaymentTermsRequest } from 'store/modules/Consortium/PaymentTerms/action'
import { GetPepRelationshipRequest } from 'store/modules/Consortium/PpeRelations/action'
import { GetPassportCountriesRequest } from 'store/modules/Consortium/PassportCountries/action'

import { IFormConsortium } from 'pages/Consortium/SolicitationProcess/Types'
import { LoadStepType } from '../../../Components/LoadData/types'

const GetPaymentTerms = (
  dispatch: React.Dispatch<any>,
  form: IFormConsortium
) => {
  dispatch(
    GetPaymentTermsRequest({
      codigo_canal: form.simulation.codigo_canal,
      codigo_produto: form.simulation.codigo_produto,
      codigo_grupo: form.proposal.grupo,
      numero_contrato: '1001305680',
      valor_credito: form.proposal.valor_credito,
    })
  )

  return null
}

export const fluxLoading: LoadStepType[] = [
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetJobsRequest()),
    selector: 'consortiumJobs',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) =>
      dispatch(GetCivilStatusRequest()),
    selector: 'consortiumCivilStatus',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) =>
      dispatch(GetPepRelationshipRequest()),
    selector: 'consortiumPpeRelations',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetTypesRequest()),
    selector: 'consortiumPpeType',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) =>
      dispatch(GetPassportCountriesRequest()),
    selector: 'consortiumPassportCountries',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetFatcaRequest()),
    selector: 'consortiumFatca',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetCountriesRequest()),
    selector: 'consortiumCountries',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetDocumentsRequest()),
    selector: 'consortiumDocuments',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetBankRequest()),
    selector: 'consortiumBank',
    callBack: () => {},
  },
  {
    method: GetPaymentTerms,
    selector: 'consortiumPaymentTerms',
    callBack: () => {},
  },
]
