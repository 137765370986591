import {
  Types,
  IPaymentTerms,
  IParamsPaymentTermsConsortiumBuilding,
} from './types'

export function GetPaymentTermsRequest(
  payload: IParamsPaymentTermsConsortiumBuilding
) {
  return {
    type: Types.GET_PAYMENT_TERMS_REQUEST,
    payload,
  }
}

export function GetPaymentTermsSuccess(payload: IPaymentTerms[]) {
  return {
    type: Types.GET_PAYMENT_TERMS_SUCCESS,
    payload,
  }
}

export function GetPaymentTermsFailure() {
  return {
    type: Types.GET_PAYMENT_TERMS_FAILURE,
  }
}
