import React from 'react'

import { GetBankRequest } from 'store/modules/Consortium/Bank/action'
import { GetJobsRequest } from 'store/modules/Consortium/Jobs/action'
import { GetFatcaRequest } from 'store/modules/Consortium/Fatca/action'
import { GetTypesRequest } from 'store/modules/Consortium/PpeTypes/action'
import { GetCountriesRequest } from 'store/modules/Consortium/Countries/action'
import { GetDocumentsRequest } from 'store/modules/Consortium/Documents/action'
import { GetCivilStatusRequest } from 'store/modules/Consortium/CivilStatus/action'
import { GetPepRelationshipRequest } from 'store/modules/Consortium/PpeRelations/action'
import { GetPaymentTermsRequest } from 'store/modules/Consortium/PaymentTerms/action'
import { GetPassportCountriesRequest } from 'store/modules/Consortium/PassportCountries/action'
import { GetClientInformationRequest } from 'store/modules/Consortium/ClientInformation/action'
import { IFormConsortium } from 'pages/Consortium/SolicitationProcess/Types'

import { LoadStepType } from '../../Components/LoadData/types'
import { INITIAL_STATE_FORM } from './types'

const GetPaymentTerms = (
  dispatch: React.Dispatch<any>,
  form: IFormConsortium
) => {
  dispatch(
    GetPaymentTermsRequest({
      codigo_canal: form.simulation.codigo_canal,
      codigo_produto: form.simulation.codigo_produto,
      codigo_grupo: form.proposal.grupo,
      numero_contrato: String(form.quoteReservation),
      valor_credito: form.proposal.valor_credito,
    })
  )

  return null
}

export const fluxLoading: LoadStepType[] = [
  {
    method: (dispatch: React.Dispatch<any>) =>
      dispatch(GetClientInformationRequest()),
    selector: 'consortiumClientInformation',
    callBack: (
      data: any,
      form: IFormConsortium,
      updateForm: (updatedKeys: IFormConsortium) => void
    ) => {
      if (!form.proposal.proponente.cpf) {
        updateForm({
          ...form,
          proposal: {
            ...form.proposal,
            proponente: {
              ...form.proposal.proponente,
              nome: data.nome,
              cpf: data.cpf,
              dt_nascimento: data.dt_nascimento,
              codigo_nacionalidade: data.codigo_nacionalidade,
              nome_mae: data.nome_mae,
              cidade_local_nascimento: data.cidade_local_nascimento,
              codigo_estado_civil: data.codigo_estado_civil,
              email: data.email,
              nome_conjuge: data.nome_conjuge,
              cpf_conjuge: data.cpf_conjuge,
              documento: data.documento,
              endereco_residencial: data.endereco_residencial,
              fatca: data.fatca
                ? data.fatca
                : INITIAL_STATE_FORM.proposal.proponente.fatca,
              telefone: {
                ddi: '55',
                ddd: data.telefone.slice(0, 2),
                numero: data.telefone.slice(2),
                tipo: data.telefone.length === 11 ? 'CELULAR' : 'FIXO',
              },
              dados_profissionais: {
                codigo: data.dados_profissionais.codigo_profissao,
                endereco_comercial: {
                  endereco_principal: true,
                },
                valor_renda_mensal: data.dados_profissionais.valor_renda_mensal,
              },
              pep: {
                grau_pep: data.pep.grau_pep || '',
                tipo: data.pep.tipo,
              },
            },
          },
        })
      }
    },
  },
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetJobsRequest()),
    selector: 'consortiumJobs',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) =>
      dispatch(GetCivilStatusRequest()),
    selector: 'consortiumCivilStatus',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) =>
      dispatch(GetPepRelationshipRequest()),
    selector: 'consortiumPpeRelations',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetTypesRequest()),
    selector: 'consortiumPpeType',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) =>
      dispatch(GetPassportCountriesRequest()),
    selector: 'consortiumPassportCountries',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetFatcaRequest()),
    selector: 'consortiumFatca',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetCountriesRequest()),
    selector: 'consortiumCountries',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetDocumentsRequest()),
    selector: 'consortiumDocuments',
    callBack: () => {},
  },
  {
    method: (dispatch: React.Dispatch<any>) => dispatch(GetBankRequest()),
    selector: 'consortiumBank',
    callBack: () => {},
  },
  {
    method: GetPaymentTerms,
    selector: 'consortiumPaymentTerms',
    callBack: () => {},
  },
]
