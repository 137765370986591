import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'styled-bootstrap-grid'

import 'styled-components/macro'
import { useToast } from 'hooks/toast'
import { StepComponentProps } from 'hooks/FluxManagement/types'
import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'
import useStackFluxManagement from 'hooks/FluxManagement/useStackFluxManagement'
import LoadData from 'pages/Consortium/SolicitationProcess/Components/LoadData'
import Text from 'components/Text'
import UserCard from 'components/Card/UserCard'
import useHashBase64Decoder from 'hooks/HashBase64Decoder'
import { Divisor, StepBar } from 'components'
import { StepComponent } from 'hooks/FluxManagement'
import { TextsConsortium } from 'pages/Consortium/commons'
import { PostSendProposalRequest } from 'store/modules/Consortium/SendProposal/action'
import { ApplicationState } from 'store/ApplicationState'
import { Steps } from 'pages/Consortium/SolicitationProcess/Types/Steps.constant'
import { ISendProposalConsortiumState } from 'store/modules/Consortium/SendProposal/types'
import { TRANSMITIR_CODIGO_PRODUTO } from 'pages/Consortium/SolicitationProcess/Types'
import {
  validationErrorDateDispatch,
  validationErrorExpeditedBody,
} from 'pages/Consortium/SolicitationProcess/HireSteps/StepOne/PersonalDataForm/DocumentCard/utils'
import { validationErrorDocument } from '../../SummaryProposal/drawers/DocumentsDrawer/utils'
import PaymentOptionsCard from './PaymentOptionsCard'
import LifeInsuranceDrawer from './LifeInsuranceDrawer'
import ConsortiumDataCard from './ConsortiumDataCard'
import useHandleScroll from './useHandleScroll'
import { INITIAL_STATE_FORM } from '../TypesPartnerOffer/Form/initialState'
import { fluxLoading } from './loadingRequests'
import { IBase64DecodedResponse } from './types'
import { IFormConsortium, Proponent } from '../TypesPartnerOffer/Form'

import * as s from './styles'

const LoadingContractData = () => {
  return (
    <LoadData fluxLoading={fluxLoading}>
      <ContractData />
    </LoadData>
  )
}

const ContractData: StepComponent = () => {
  const [formData, setFormData] = useState<Proponent>()
  const [submitCount, setSubmitCount] = useState<number>(0)
  const [errorDocument, setErrorDocument] = useState(false)
  const [errorInvalidDate, setErrorInvalidDate] = useState(false)
  const [errorExpeditedBody, setErrorExpeditedBody] = useState(false)
  const [cardErrors, setCardErrors] = useState(false)
  const handleScroll = useHandleScroll(3)
  const { hash } = useParams<{ hash: string }>()
  const { hashDecoded } = useHashBase64Decoder<IBase64DecodedResponse>(hash)
  const dispatch = useDispatch()
  const { addToast } = useToast()
  const { instantiate } = useStackFluxManagement<StepComponentProps>()
  const { menuPro, buttons, hire, pro } = TextsConsortium()
  const { form, updateForm } = useFormFluxManagement<IFormConsortium>()
  const [isLifeInsuranceDrawerOpen, setIsLifeInsuranceDrawerOpen] = useState(
    false
  )

  const lifeInsurance = true

  const clientPROInformation = useSelector(
    (state: ApplicationState) => state.consortiumClientPROInformation.data
  )

  const { data: numero_reserva } = useSelector(
    (state: ApplicationState) => state.consortiumQuoteReservation
  )

  const {
    isLoading: isSubmitLoading,
    data: sendProposalData,
    hasError,
  } = useSelector(
    (state: { consortiumSendProposal: ISendProposalConsortiumState }) =>
      state.consortiumSendProposal
  )

  const activateMenu = React.useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const offset2: number = handleScroll?.sectionTwo.current?.offsetTop || 0
      const offset3: number =
        handleScroll?.sectionThree?.current?.offsetTop || 0
      const height3: number =
        handleScroll?.sectionThree?.current?.offsetHeight || 0

      if (e.currentTarget.scrollTop > offset2) {
        handleScroll.setActiveSection('section-2')
      }
      if (e.currentTarget.scrollTop > offset3 - height3) {
        handleScroll.setActiveSection('section-3')
      }
      if (e.currentTarget.scrollTop < offset2) {
        handleScroll.setActiveSection('section-1')
      }
    },
    [handleScroll]
  )

  useEffect(() => {
    if (!formData && clientPROInformation) {
      const proponentObj = {
        ...form.proposal.proponente,
        ...clientPROInformation,
        fatca: clientPROInformation.fatca
          ? clientPROInformation.fatca
          : INITIAL_STATE_FORM.proposal.proponente.fatca,
        telefone: {
          ddi: '55',
          ddd: clientPROInformation.telefone.slice(0, 2),
          numero: clientPROInformation.telefone.slice(2),
          tipo:
            clientPROInformation.telefone.length === 11 ? 'CELULAR' : 'FIXO',
        },
        dados_profissionais: {
          codigo: clientPROInformation.dados_profissionais.codigo_profissao,
          endereco_comercial: {
            endereco_principal: true,
          },
          valor_renda_mensal:
            clientPROInformation.dados_profissionais.valor_renda_mensal,
        },
        pep: {
          grau_pep: clientPROInformation.pep.grau_pep || '',
          tipo: clientPROInformation.pep.tipo,
        },
      }

      updateForm({
        ...form,
        proposal: {
          ...form.proposal,
          numero_sequencia_proposta: numero_reserva || 0,
          proponente: proponentObj,
        },
      })
      setFormData(proponentObj)
    }
  }, [formData, updateForm, form, numero_reserva, clientPROInformation])

  useEffect(() => {
    if (formData) {
      const valErrorDocument = validationErrorDocument(
        formData.documento.numero,
        true,
        formData.documento.codigo
      )

      const valErrorInvalidDate = validationErrorDateDispatch(
        formData.documento.dt_expedicao,
        true
      )
      const valErrorExpeditedBody = validationErrorExpeditedBody(
        formData.documento.orgao_emissor,
        true
      )

      setErrorDocument(!!valErrorDocument)
      setErrorInvalidDate(!!valErrorInvalidDate)
      setErrorExpeditedBody(!!valErrorExpeditedBody)
      setCardErrors(errorDocument || errorInvalidDate || errorExpeditedBody)
    }
  }, [formData, errorDocument, errorInvalidDate, errorExpeditedBody])

  React.useEffect(() => {
    if (sendProposalData) {
      instantiate(Steps.REQUEST_RESULT_PRO)
    }

    if (hasError) {
      addToast({
        type: 'error',
        title: 'Erro ao enviar solicitação. ',
        description: 'Por favor, tente novamente.',
      })
    }

    if (submitCount === 3 && hasError) {
      instantiate(Steps.REQUEST_RESULT_PRO)
    }
  }, [submitCount, hasError, instantiate, sendProposalData, addToast])

  const handleClick = React.useCallback(() => {
    if (formData) {
      updateForm({
        ...form,
        proposal: {
          ...form.proposal,
          proponente: {
            ...formData,
            dados_bancarios: {
              codigo_banco: formData.dados_bancarios.nome_banco.split(' - ')[0],
              digito_agencia: formData.dados_bancarios.numero_agencia.substr(
                -1
              ),
              digito_conta: formData.dados_bancarios.digito_conta,
              nome_banco: formData.dados_bancarios.nome_banco.split(' - ')[1],
              numero_agencia: formData.dados_bancarios.numero_agencia,
              numero_conta: formData.dados_bancarios.numero_conta,
            },
          },
          forma_pagamento_adesao:
            formData.dados_bancarios.forma_pagamento_adesao ||
            'DEBITO_AUTOMATICO_EM_CONTA_CORRENTE',
          condicao_pagamento_adesao: formData.dados_bancarios
            .earlyAdministrationFree || {
            codigo: 0,
            descricao: '',
            nome: '',
            parcelas: [],
            tipoCliente: '',
          },
        },
      })

      const proposal = {
        ...form.proposal,
        codigo_produto: TRANSMITIR_CODIGO_PRODUTO.IMOVEL,
        codigo_canal: '1',
        seguro_vida: form.proposal.seguro_vida,
        fundo_reserva: form.proposal.fundo_reserva,
        valor_primeira_parcela: form.proposal.valor_primeira_parcela,
        valor_demais_parcelas: form.proposal.valor_demais_parcelas,
        representante: 604377,
        proponente: {
          ...formData,
          dados_bancarios: {
            codigo_banco: formData.dados_bancarios.nome_banco.split(' - ')[0],
            digito_agencia: formData.dados_bancarios.numero_agencia.substr(-1),
            digito_conta: formData.dados_bancarios.digito_conta,
            nome_banco: formData.dados_bancarios.nome_banco.split(' - ')[1],
            numero_agencia: formData.dados_bancarios.numero_agencia,
            numero_conta: formData.dados_bancarios.numero_conta,
          },
        },
        forma_pagamento_adesao:
          formData.dados_bancarios.forma_pagamento_adesao ||
          'DEBITO_AUTOMATICO_EM_CONTA_CORRENTE',
        forma_pagamento_adesao_boleto: false,
        condicao_pagamento_adesao: formData.dados_bancarios
          .earlyAdministrationFree || {
          codigo: 0,
          descricao: '',
          nome: '',
          parcelas: [],
          tipoCliente: '',
        },
      }

      dispatch(
        PostSendProposalRequest({
          ...proposal,
        })
      )

      setSubmitCount(submitCount + 1)
    }
  }, [form, formData, submitCount, setSubmitCount, dispatch, updateForm])

  if (!formData) {
    return null
  }

  return (
    <s.ScrollableContainer onScroll={activateMenu}>
      <StepBar
        title="Proposta"
        type="Consórcio de Imóvel"
        actualStep={3}
        totalNumberOfSteps={3}
      />
      <LifeInsuranceDrawer
        isOpen={isLifeInsuranceDrawerOpen}
        handleClose={() => setIsLifeInsuranceDrawerOpen(false)}
      />
      {hashDecoded && (
        <>
          <s.ContainerCmp>
            <Row>
              <Col col={3}>
                <s.NavWrapper>
                  <s.NavTitleWrapper>
                    <Text type="subheading" bold color="gray6">
                      DADOS DA PROPOSTA
                    </Text>
                  </s.NavTitleWrapper>
                  <s.TagsWrapper>
                    <s.Tag
                      active={handleScroll.activeMenuItem === 'section-1'}
                      onClick={() => {
                        handleScroll.scrollToSection(handleScroll.sectionOne)
                        handleScroll.setActiveSection('section-1')
                      }}
                    >
                      <s.TagText>{menuPro.paymentItemData}</s.TagText>
                      {cardErrors && <s.BadgeNotification />}
                    </s.Tag>
                    <s.Tag
                      active={handleScroll.activeMenuItem === 'section-2'}
                      onClick={() => {
                        handleScroll.scrollToSection(handleScroll.sectionTwo)
                        handleScroll.setActiveSection('section-2')
                      }}
                    >
                      <s.TagText>{menuPro.paymentOption}</s.TagText>
                    </s.Tag>
                    <s.Tag
                      active={handleScroll.activeMenuItem === 'section-3'}
                      onClick={() => {
                        handleScroll.scrollToSection(handleScroll.sectionThree)
                        handleScroll.setActiveSection('section-3')
                      }}
                    >
                      <s.TagText>{menuPro.lifeInsurance}</s.TagText>
                    </s.Tag>
                  </s.TagsWrapper>
                  <Divisor css="margin: 21px 0;" />
                  <s.NavTitleWrapper>
                    <Text type="subheading" color="gray5" bold>
                      ENVIAR PROPOSTA PARA:
                    </Text>
                  </s.NavTitleWrapper>
                  <UserCard
                    name={hashDecoded.nomeCliente || ''}
                    type="cliente"
                  />
                </s.NavWrapper>
              </Col>
              <Col col={8} id="column-2">
                <section id="section-1" ref={handleScroll.sectionOne}>
                  <s.WrapperCard>
                    <ConsortiumDataCard
                      proposalData={[formData, setFormData]}
                    />
                  </s.WrapperCard>
                </section>
                <section id="section-2" ref={handleScroll.sectionTwo}>
                  <s.WrapperCard>
                    <PaymentOptionsCard
                      proposalData={[formData, setFormData]}
                    />
                  </s.WrapperCard>
                </section>
                {lifeInsurance ? (
                  <section id="section-3" ref={handleScroll.sectionThree}>
                    <s.WrapperCardInsured>
                      <s.TitleCard>{menuPro.lifeInsurance}</s.TitleCard>
                      <s.ArticleCard>
                        <s.TextArticleCard>
                          {pro.lifeInsurance[0]}
                        </s.TextArticleCard>
                        <s.LinkArticleCard>
                          {pro.lifeInsurance[1]}
                        </s.LinkArticleCard>
                        <s.TextArticleCard>
                          {pro.lifeInsurance[2]}
                        </s.TextArticleCard>
                      </s.ArticleCard>
                      <s.ButtonCard
                        onClick={() => setIsLifeInsuranceDrawerOpen(true)}
                      >
                        {pro.button}
                      </s.ButtonCard>
                    </s.WrapperCardInsured>
                  </section>
                ) : (
                  <section id="section-3" ref={handleScroll.sectionThree}>
                    <s.WrapperCardInsured>
                      <s.TitleCard>
                        {hire.steps.insuranceLender.title}
                      </s.TitleCard>
                      <s.ArticleCard>
                        <s.TextArticleCard>
                          {pro.lifeInsurance[0]}
                        </s.TextArticleCard>
                        <s.LinkArticleCard>
                          {pro.lifeInsurance[1]}
                        </s.LinkArticleCard>
                        <s.TextArticleCard>
                          {pro.lifeInsurance[2]}
                        </s.TextArticleCard>
                      </s.ArticleCard>
                      <s.ButtonCard>{pro.button}</s.ButtonCard>
                    </s.WrapperCardInsured>
                  </section>
                )}
              </Col>
            </Row>
          </s.ContainerCmp>
          <s.FooterContainer>
            <s.FooterContent>
              <s.RowContent>
                <s.Column lg={3} />
                <s.Column lg={8} md={11}>
                  <s.ButtonAlign>
                    <s.NextButton
                      data-testid="button-next-id"
                      disabled={cardErrors}
                      color="secondary"
                      onClick={() => handleClick()}
                      loading={isSubmitLoading}
                    >
                      {buttons.senderProposal}
                    </s.NextButton>
                  </s.ButtonAlign>
                </s.Column>
              </s.RowContent>
            </s.FooterContent>
          </s.FooterContainer>
        </>
      )}
    </s.ScrollableContainer>
  )
}

export default LoadingContractData
