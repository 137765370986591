import React from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Container, Row } from 'styled-bootstrap-grid'

import { StepComponentProps } from 'hooks/FluxManagement/types'
import useStackFluxManagement from 'hooks/FluxManagement/useStackFluxManagement'

import Modal from 'components/Modal/ModalNew'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import * as s from './styles'

const WrapperPro: React.FC = ({ children }) => {
  const { modalFlowExit } = TextsConsortium()
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
  const history = useHistory()

  const { getLength, reset } = useStackFluxManagement<StepComponentProps>()

  const redirectToHome = () => {
    reset()

    history.push('/')
  }

  const modalFooterButton = () => (
    <s.ButtonWrapper>
      <s.Button
        data-gtm-type="click"
        data-gtm-clicktype="button"
        data-gtm-name="sair"
        outline
        onClick={redirectToHome}
      >
        {modalFlowExit.button.exit}
      </s.Button>
      <s.Button
        data-gtm-type="click"
        data-gtm-clicktype="button"
        data-gtm-name="continuar solicitação"
        onClick={() => setIsModalOpen(false)}
      >
        {modalFlowExit.button.continue}
      </s.Button>
    </s.ButtonWrapper>
  )

  return (
    <>
      <Modal
        modalTitle={modalFlowExit.title}
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        <s.Text type="headline" color="gray6">
          {modalFlowExit.content}
        </s.Text>
        {modalFooterButton()}
      </Modal>
      <s.Layer
        isOpen
        fullHeight={getLength() < 2}
        handleClose={() => setIsModalOpen(true)}
        data-testid="consortium-building-layer"
        headerContent={
          <Container>
            <Row>
              <Col lg={12}>
                <s.HeaderContent>
                  <s.LogoWrapper>
                    <s.Logo />
                  </s.LogoWrapper>

                  <s.CloseIcon
                    className="layer-close-button"
                    data-gtm-subname="botao fechar layer"
                    data-gtm-name="botao fechar"
                    data-gtm-type="click"
                    data-gtm-clicktype="button"
                    onClick={() => setIsModalOpen(true)}
                  />
                </s.HeaderContent>
              </Col>
            </Row>
          </Container>
        }
      >
        {children}
      </s.Layer>
    </>
  )
}

export default WrapperPro
