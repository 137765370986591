import { Proponent } from 'pages/Consortium/SolicitationProcess/Types/Form'
import { Phone } from 'pages/Consortium/SolicitationProcess/Types/Form/Phone'
import { Fatca } from 'pages/Consortium/SolicitationProcess/Types/Form/Fatca'
import { Address } from 'pages/Consortium/SolicitationProcess/Types/Form/Address'
import { Document } from 'pages/Consortium/SolicitationProcess/Types/Form/Document'
import { BankDetails } from 'pages/Consortium/SolicitationProcess/Types/Form/BankDetails'
import { PEPType } from 'pages/Consortium/SolicitationProcess/Types/Form/ConsortiumPEPType'
import { Professional } from 'pages/Consortium/SolicitationProcess/Types/Form/Professional'

import { dict } from 'components/IconExporter'
import { IPaymentTerms } from 'store/modules/Consortium/PaymentTerms/types'

import {
  TIPO_PESSOA,
  TIPO_BUSCA,
  CODIGO_PRODUTO,
  CODIGO_CANAL,
  IFormConsortium,
} from '../../Types'

const document: Document = {
  codigo: '',
  numero: '',
  orgao_emissor: '',
  dt_expedicao: '',
}

const pepType: PEPType = {
  tipo: '',
  grau_pep: '',
}

const address: Address = {
  cep: '',
  logradouro: '',
  numero: '',
  bairro: '',
  cidade: '',
  complemento: '',
  uf: '',
  endereco_principal: true,
}

const phone: Phone = {
  ddi: '',
  ddd: '',
  numero: '',
  tipo: '',
}

const fatca: Fatca = {
  possui_residencia_fiscal: false,
  fatca: '',
  pais_residencia_fiscal: '',
  numero_identificacao_fiscal: '',
  numero_passaporte: '',
  pais_passaporte: '',
}

const professional: Professional = {
  codigo: 0,
  valor_renda_mensal: 0,
  endereco_comercial: {
    endereco_principal: true,
  },
}

const bankDetails: BankDetails = {
  codigo_banco: '',
  nome_banco: '',
  numero_agencia: '',
  numero_conta: '',
  digito_conta: '',
  digito_agencia: '',
}

const proponent: Proponent = {
  nome: '',
  cpf: '',
  dt_nascimento: '',
  codigo_nacionalidade: '',
  nome_mae: '',
  cidade_local_nascimento: '',
  codigo_estado_civil: '',
  email: '',
  nome_conjuge: '',
  cpf_conjuge: '',
  documento: document,
  pep: pepType,
  endereco_residencial: address,
  telefone: phone,
  fatca: fatca,
  dados_profissionais: professional,
  dados_bancarios: bankDetails,
}

const earlyAdministrationFree: IPaymentTerms = {
  codigo: 0,
  descricao: '',
  nome: '',
  parcelas: [],
  tipoCliente: '',
}

export const INITIAL_STATE_FORM: IFormConsortium = {
  simulation: {
    tipo_pessoa: TIPO_PESSOA.FISICA,
    tipo_busca: TIPO_BUSCA.CREDITO_E_PARCELA,
    codigo_produto: CODIGO_PRODUTO.IMOVEL,
    codigo_canal: CODIGO_CANAL.CUSTOMER,
    uf: '',
    valor_credito: 0,
  },
  proposal: {
    codigo_canal: '',
    numero_sequencia_proposta: 0,
    codigo_produto: 'IMOVEL',
    valor_credito: 0,
    forma_pagamento_adesao: '',
    forma_pagamento_adesao_boleto: false,
    proponente: proponent,
    codigo_bem: '',
    dt_venc_assembl: '',
    grupo: '',
    tipo_lance: '',
    percentual_lance_embutido: 0,
    numero_participantes: 0,
    produto_sugerido: '',
    dt_assembleia: '',
    informacao_produto: '',
    seguro_vida: 0,
    obs_seguro_vida: '',
    desc_produto: '',
    prazo: 0,
    taxa_adesao: 0,
    percentual_lance_livre: 0,
    fundo_reserva: 0,
    percentual_lance_fixo: 0,
    valor_primeira_parcela: 0,
    valor_demais_parcelas: 0,
    data_primeiro_pagamento: '',
    cota: 0,
    parcela_escolhida: 0,
    total_vagas: '',
    taxa_administrativa: 0,
    credito_escolhido: 0,
    numero_parcelas_escolhida: 0,
    tipo_grupo: '',
    tipo_consorcio: '',
    assemb_participacao: '',
    representante: 604377,
    condicao_pagamento_adesao: earlyAdministrationFree,
  },
}

export type IValidationFunction =
  | { icon: keyof typeof dict; message: string }
  | undefined
