import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TOptions, TSelected } from 'components/Inputs/Select/types'
import { IFormConsortium } from 'pages/Consortium/SolicitationProcess/Types'

import { ApplicationState } from 'store/ApplicationState'
import { ProposalSimulationsClearData } from 'store/modules/Consortium/ProposalSimulations/action'
import { State } from 'store/modules/Consortium/States/types'
import { GetStatesRequest } from 'store/modules/Consortium/States/action'
import useMedia from 'hooks/useMedia'
import {
  StepComponentProps,
  useFormFluxManagement,
  useStackFluxManagement,
} from 'hooks/FluxManagement'
import { formatMoneyDecimal, formatNumberToDecimalMoney } from 'helpers'
import { ErrorForm } from 'pages/Consortium/commons/ErrorForm'

import ChangeSimulationDataMobile from './Mobile'
import ChangeSimulationDataDrawer from './Drawer'

import * as s from './styles'

export interface ChangeSimulationDataProps {
  isOpen: boolean
  handleClose: () => void
  isLoading?: boolean
}
const ChangeSimulationData = ({
  handleClose,
  isOpen,
}: ChangeSimulationDataProps) => {
  const isMobile = useMedia('xs')
  const dispatch = useDispatch()
  const { updateForm, form } = useFormFluxManagement<IFormConsortium>()
  const { pop } = useStackFluxManagement<StepComponentProps>()

  const [uf, setUf] = useState('')
  const [states, setStates] = useState<TOptions[]>([])
  const [selectedState, setSelectedState] = useState<TSelected>({
    id: 0,
    name: '',
  })
  const [contemplatedValueInput, setContemplatedValueInput] = useState({
    value: '',
    touched: false,
  })

  const {
    statesData,
    contemplatedValueData,
    isSimulationLoading,
  } = useSelector((state: ApplicationState) => ({
    statesData: state.consortiumStates.data,
    contemplatedValueData: state.contemplated.data,
    isSimulationLoading: state.consortiumProposalSimulations.isLoading,
  }))

  const isValid = useMemo(() => {
    if (contemplatedValueData) {
      const minValue = contemplatedValueData.valor_minimo_credito
      const maxValue = contemplatedValueData.valor_maximo_credito
      const currentValue =
        +contemplatedValueInput.value.replace(/\D/gm, '') / 100

      return minValue <= currentValue && maxValue >= currentValue
    }
    return false
  }, [contemplatedValueData, contemplatedValueInput])

  useEffect(() => {
    if (form.simulation && statesData) {
      const formSelectedState = statesData.ufs.find(
        state => state.codigo === form.simulation.uf
      )
      setContemplatedValueInput({
        value: formatNumberToDecimalMoney(form.simulation.valor_credito || 0),
        touched: false,
      })
      setUf(form.simulation.uf)
      if (formSelectedState) {
        setSelectedState({
          id: formSelectedState.codigo,
          name: formSelectedState.descricao,
        })
      }
    }
  }, [form.simulation, statesData])

  useEffect(() => {
    if (!statesData) {
      dispatch(GetStatesRequest())
    } else {
      const statesOptions = statesData.ufs.map((state: State) => ({
        id: state.codigo,
        text: state.descricao,
        onClick: () => {
          setUf(state.codigo)
          setSelectedState({
            id: state.codigo,
            name: state.descricao,
          })
        },
      }))
      setStates([...statesOptions])
    }
  }, [statesData, dispatch])

  const onHandleMoneyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (contemplatedValueData) {
      const maxValue = contemplatedValueData.valor_maximo_credito
      const currentValue = +e.target.value.replace(/\D/gm, '') / 100

      if (currentValue >= maxValue) {
        setContemplatedValueInput({
          value: formatMoneyDecimal((maxValue * 100).toString()),
          touched: false,
        })
        return
      }
    }
    setContemplatedValueInput({ value: e.target.value, touched: false })
  }

  const validateMoneyValue = () => {
    if (contemplatedValueData) {
      const formattedMinValue = formatMoneyDecimal(
        contemplatedValueData.valor_minimo_credito * 100
      )
      const formattedMaxValue = formatMoneyDecimal(
        contemplatedValueData.valor_maximo_credito * 100
      )
      const stepMessage = `R$ ${formattedMinValue} e R$ ${formattedMaxValue}`

      const { coveredValueValidationMessage } = ErrorForm(stepMessage)

      if (!isValid && contemplatedValueInput.touched) {
        return coveredValueValidationMessage
      }
    }
    return false
  }

  const onSubmitSimulation = () => {
    if (isValid) {
      const valor_credito =
        +contemplatedValueInput.value.replace(/\D/gm, '') / 100
      const payload = {
        tipo_pessoa: 1,
        tipo_busca: 3,
        codigo_produto: 1,
        codigo_canal: 7,
        valor_credito,
        uf,
      }
      updateForm({
        ...form,
        simulation: { ...payload },
      })
      dispatch(ProposalSimulationsClearData())
      handleClose()
      pop()
    }
  }

  return (
    <s.Container>
      {isMobile ? (
        <ChangeSimulationDataMobile
          isOpen={isOpen}
          handleMoneyChange={onHandleMoneyChange}
          isLoading={isSimulationLoading}
          isValid={isValid}
          validateMoneyChange={validateMoneyValue}
          contemplatedValueInput={contemplatedValueInput}
          setContemplatedValueInput={setContemplatedValueInput}
          handleClose={handleClose}
          handleSimulationSubmit={onSubmitSimulation}
          selectedState={selectedState}
          statesOptions={states}
        />
      ) : (
        <ChangeSimulationDataDrawer
          isOpen={isOpen}
          handleMoneyChange={onHandleMoneyChange}
          isLoading={isSimulationLoading}
          isValid={isValid}
          validateMoneyChange={validateMoneyValue}
          contemplatedValueInput={contemplatedValueInput}
          setContemplatedValueInput={setContemplatedValueInput}
          handleClose={handleClose}
          handleSimulationSubmit={onSubmitSimulation}
          selectedState={selectedState}
          statesOptions={states}
        />
      )}
    </s.Container>
  )
}

export default ChangeSimulationData
