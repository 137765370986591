import { all, takeLatest, call, put } from 'redux-saga/effects'

import { get, baseURLNamespaceConsortium } from 'services/api'
import { AxiosResponse } from 'axios'
import {
  IParamsPaymentTermsConsortiumBuilding,
  IPaymentTermsConsortiumBuildingReturn,
  IPaymentTermsReturn,
  Types,
} from './types'
import { GetPaymentTermsSuccess, GetPaymentTermsFailure } from './action'

export function* getPaymentTerms({
  payload,
}: {
  type: string
  payload: IParamsPaymentTermsConsortiumBuilding
}) {
  try {
    const {
      data,
    }: AxiosResponse<IPaymentTermsConsortiumBuildingReturn> = yield call(
      get,
      `${baseURLNamespaceConsortium}v2/consorcios/pagamentos/condicoes`,
      { params: payload }
    )

    const terms = data.CondicaoAdesao.map((item: IPaymentTermsReturn) => {
      return {
        codigo: item.codigo,
        descricao: item.descricao,
        nome: item.nome,
        parcelas: item.Parcela.map(value => ({
          taxaAdministracaoAntecipada: value.antecipacaoTaxaAdministracao,
          descricao: value.descricao,
          fundoReserva: value.fundoReserva,
          taxaAdministracao: value.taxaAdministracao,
          valor: value.valor,
        })),
        tipoCliente: item.tipoCliente,
      }
    })

    if (terms) {
      yield put(GetPaymentTermsSuccess(terms))
    } else {
      yield put(GetPaymentTermsSuccess([]))
    }
  } catch (err) {
    yield put(GetPaymentTermsFailure())
  }
}

export default all([
  takeLatest(Types.GET_PAYMENT_TERMS_REQUEST, getPaymentTerms),
])
