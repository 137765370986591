import { all, takeLatest, call, put } from 'redux-saga/effects'

import { get, baseURLNamespaceConsortium } from 'services/api'
import { AxiosResponse } from 'axios'
import { IContemplatedData, Types } from './types'
import {
  GetContemplatedIntervalDataSuccess,
  GetContemplatedIntervalDataFailure,
} from './action'

export function* getData() {
  const getContemplatedIntervalDataURL = `${baseURLNamespaceConsortium}v2/consorcios/produtos/1/valores`

  try {
    const response: AxiosResponse<IContemplatedData> = yield call(
      get,
      getContemplatedIntervalDataURL
    )
    yield put(GetContemplatedIntervalDataSuccess({ data: response.data }))
  } catch (err) {
    yield put(GetContemplatedIntervalDataFailure())
  }
}

export default all([takeLatest(Types.GET_REQUEST, getData)])
