import { IToast } from 'hooks/toast'

export const Types = {
  POST_PROPOSAL_SIMULATIONS_REQUEST: 'POST_PROPOSAL_SIMULATIONS_REQUEST',
  POST_PROPOSAL_SIMULATIONS_SUCCESS: 'POST_PROPOSAL_SIMULATIONS_SUCCESS',
  POST_PROPOSAL_SIMULATIONS_FAILURE: 'POST_PROPOSAL_SIMULATIONS_FAILURE',
  PROPOSAL_SIMULATIONS_CLEAR_DATA: 'PROPOSAL_SIMULATIONS_CLEAR_DATA',
}

export interface IAction {
  type: keyof typeof Types
  payload: ProposalSimulationsResponse
}

export interface Simulation {
  id?: string
  codigo_grupo: string
  numero_cota: number
  valor_parcela: number
  valor_credito: number
  prazo: number
  fundo_reserva: number
  taxa_administrativa: number
  seguro_vida: number
  cod_bem: string
  informacao_produto: string
  assemb_participacao: string
  dt_assembleia: string
  dt_avencto_assembl: string
  total_vagas: number
  taxa_adesao: number
  taxa_administracao: number
  tipos_de_lance: string
  desc_produto: string
  fd_reserva: number
  tipo_grupo: string
  valor_primeira_parcela: number
  valor_demais_parcelas: number
  valor_adesao: number
  numero_participantes: number
  percentual_lance_fixo: number
  percentual_lance_livre: number
  percentual_lance_embutido: number
  produto_sugerido: string
}

export interface IProposalSimulationsRequest {
  tipo_pessoa: number
  tipo_busca: number
  codigo_produto: number
  valor_credito: number
  uf: string
  codigo_canal: number
}

export interface ProposalSimulationsResponse {
  simulacoes: Simulation[]
}

export interface IPayload {
  type: keyof typeof Types
  payload: IProposalSimulationsRequest
  onErrorToast: (toast: Omit<IToast, 'id'>) => void
  onSuccess?: () => void
}

export interface IConsortiumProposalSimulation {
  data: Simulation[] | null
  isLoading: boolean
  hasError: boolean
}
