import React from 'react'

import useMedia from 'hooks/useMedia'

import Drawer from 'components/Drawer'
import Modal from 'components/Modal/ModalNew'

import { ITexts } from 'pages/Consortium/commons/Texts/types'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import PaymentData from './PaymentData'
import FirstInstallmentPayment from './FirstInstallmentPayment'

import { IPaymentDataForm } from './types'
import { DrawerType } from '../../types'
import * as s from './styles'

const ChangePaymentData: React.FC<DrawerType> = ({
  isOpen,
  handleClose,
  proposalData: [proposalData, setProposalData],
  isValidForm: [isValidForm, setIsValidForm],
}) => {
  const [texts, setTexts] = React.useState<ITexts>(TextsConsortium())

  const isMobile = useMedia('sm')

  const [paymentMethod, setPaymentMethod] = React.useState<string>(
    proposalData.forma_pagamento_adesao || ''
  )

  const [paymentData, setPaymentData] = React.useState<IPaymentDataForm>({
    code: String(proposalData.proponente.dados_bancarios.codigo_banco),
    bank: String(proposalData.proponente.dados_bancarios.nome_banco),
    agency: String(proposalData.proponente.dados_bancarios.numero_agencia),
    currentAccount: String(
      proposalData.proponente.dados_bancarios.numero_conta
    ),
    digit: String(proposalData.proponente.dados_bancarios.digito_conta),
    earlyAdministrationFree: {
      parcelas: proposalData.condicao_pagamento_adesao?.parcelas || [],
      codigo: proposalData.condicao_pagamento_adesao?.codigo || 0,
      descricao: proposalData.condicao_pagamento_adesao?.descricao || '',
      nome: proposalData.condicao_pagamento_adesao?.nome || '',
      tipoCliente: proposalData.condicao_pagamento_adesao?.tipoCliente || '',
    },
  })

  const handleCloseDrawer = React.useCallback(() => {
    setPaymentData({
      code: String(proposalData.proponente.dados_bancarios.codigo_banco),
      bank: String(proposalData.proponente.dados_bancarios.nome_banco),
      agency: String(proposalData.proponente.dados_bancarios.numero_agencia),
      currentAccount: String(
        proposalData.proponente.dados_bancarios.numero_conta
      ),
      digit: String(proposalData.proponente.dados_bancarios.digito_conta),
      earlyAdministrationFree: {
        parcelas: proposalData.condicao_pagamento_adesao.parcelas,
        codigo: proposalData.condicao_pagamento_adesao.codigo,
        descricao: proposalData.condicao_pagamento_adesao.descricao,
        nome: proposalData.condicao_pagamento_adesao.nome,
        tipoCliente: proposalData.condicao_pagamento_adesao.tipoCliente,
      },
    })

    setPaymentMethod(proposalData.forma_pagamento_adesao || '')

    handleClose()
  }, [proposalData, handleClose])

  const handleClick = React.useCallback(() => {
    setProposalData({
      ...proposalData,
      forma_pagamento_adesao: paymentMethod,
      condicao_pagamento_adesao: paymentData.earlyAdministrationFree,
      proponente: {
        ...proposalData.proponente,
        dados_bancarios: {
          codigo_banco: paymentData.code,
          nome_banco: paymentData.bank,
          numero_agencia: paymentData.agency,
          numero_conta: paymentData.currentAccount,
          digito_conta: paymentData.digit,
          digito_agencia: paymentData.agency.substr(-1),
        },
      },
    })

    setIsValidForm({
      ...isValidForm,
      paymentForm: true,
    })

    handleClose()
    return null
  }, [
    proposalData,
    paymentData,
    paymentMethod,
    isValidForm,
    setProposalData,
    handleClose,
    setIsValidForm,
  ])

  const renderContent = () => (
    <s.Wrapper>
      <s.CaptionText>
        {texts.hire.steps.payment.containerSecond.title}
      </s.CaptionText>
      <FirstInstallmentPayment
        paymentMethod={[paymentMethod, setPaymentMethod]}
        content={texts.hire.steps.payment.containerSecond}
        isDrawer
      />
      <s.CaptionTextSecond>
        {texts.hire.steps.payment.containerSecond.subtitleDrawer}
      </s.CaptionTextSecond>
      <PaymentData
        paymentData={[paymentData, setPaymentData]}
        content={texts.hire.steps.payment.containerThird}
        amountOtherInstallments={String(proposalData.valor_demais_parcelas)}
        isDrawer
      />
    </s.Wrapper>
  )

  React.useEffect(() => {
    if (proposalData.valor_primeira_parcela) {
      setTexts(TextsConsortium(''))
    }
  }, [proposalData])

  const footerContent = () => (
    <s.Button
      data-gtm-name={texts.buttons.saveChanges}
      data-gtm-type="click"
      data-gtm-clicktype="button"
      onClick={() => handleClick()}
    >
      {texts.buttons.saveChanges}
    </s.Button>
  )

  return isMobile ? (
    <Modal
      isOpen={isOpen}
      handleClose={handleCloseDrawer}
      modalTitle={texts.hire.steps.payment.drawerTitle}
      footer={footerContent()}
    >
      {renderContent()}
    </Modal>
  ) : (
    <Drawer
      isOpen={isOpen}
      handleClose={handleCloseDrawer}
      drawerTitle={texts.hire.steps.payment.drawerTitle}
      footer={footerContent()}
      size="sm"
    >
      {renderContent()}
    </Drawer>
  )
}

export default ChangePaymentData
