import { all, takeLatest, call, put } from 'redux-saga/effects'

import { get, baseURLNamespaceInvest } from 'services/api'
import { loggerSucess, loggerError } from 'logger'
import { Types } from './types'
import { GetConsortiumDataSuccess, GetConsortiumDataFailure } from './action'

export function* getData() {
  const getConsortiumDataURL = `${baseURLNamespaceInvest}/consorcios/imovel?codigoCanal=1`
  try {
    const response = yield call(get, getConsortiumDataURL)

    let responseData = response.data

    if (response.data.Mensagem) {
      responseData = []
    }

    yield put(GetConsortiumDataSuccess(responseData.consorcios || []))
    loggerSucess(getConsortiumDataURL, 'GET', response?.status)
  } catch (err) {
    yield put(GetConsortiumDataFailure())
    loggerError(getConsortiumDataURL, 'GET', err)
  }
}

export default all([takeLatest(Types.GET_REQUEST, getData)])
