export const Types = {
  GET_PAYMENT_TERMS_REQUEST: 'GET_PAYMENT_TERMS_REQUEST',
  GET_PAYMENT_TERMS_SUCCESS: 'GET_PAYMENT_TERMS_SUCCESS',
  GET_PAYMENT_TERMS_FAILURE: 'GET_PAYMENT_TERMS_FAILURE',
}

export interface IAction {
  type: keyof typeof Types
  payload: IPaymentTermsConsortiumBuilding
}

export interface IPaymentTermsInstallment {
  taxaAdministracaoAntecipada: number
  descricao: string
  fundoReserva: number
  taxaAdministracao: number
  valor: number
}

export interface IPaymentTermsInstallmentReturn {
  antecipacaoTaxaAdministracao: number
  codigo: number
  descricao: string
  fundoReserva: number
  taxaAdministracao: number
  valor: number
}

export interface IPaymentTerms {
  codigo: number
  descricao: string
  nome: string
  parcelas: IPaymentTermsInstallment[]
  tipoCliente: string
}

export interface IPaymentTermsReturn {
  codigo: number
  descricao: string
  nome: string
  Parcela: IPaymentTermsInstallmentReturn[]
  tipoCliente: string
}

export interface IPaymentTermsConsortiumBuilding {
  CondicaoAdesao: IPaymentTerms[]
}

export interface IPaymentTermsConsortiumBuildingReturn {
  CondicaoAdesao: IPaymentTermsReturn[]
}

export interface IParamsPaymentTermsConsortiumBuilding {
  codigo_canal: number
  codigo_produto: number
  codigo_grupo: string
  numero_contrato: string
  valor_credito: number
}

export interface IPaymentTermsConsortiumBuildingState {
  readonly data: IPaymentTerms[] | null
  readonly isLoading: boolean
  readonly hasError: boolean
}
