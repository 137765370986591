import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import useStackFluxManagement from 'hooks/FluxManagement/useStackFluxManagement'
import {
  StepComponentProps,
  StepComponent,
  useFormFluxManagement,
} from 'hooks/FluxManagement'
import useHashBase64Decoder from 'hooks/HashBase64Decoder'
import { useToast } from 'hooks/toast'

import { StepBar } from 'components'
import UserCard from 'components/Card/UserCard'
import { TOptions, TSelected } from 'components/Inputs/Select/types'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { TIPO_CONSORCIO } from 'pages/Consortium/SolicitationProcess/Types'

import { ApplicationState } from 'store/ApplicationState'
import { Simulation } from 'store/modules/Consortium/ProposalSimulations/types'
import { IQuoteReservation } from 'store/modules/Consortium/QuoteReservation/types'
import { PostQuotesRequest } from 'store/modules/Consortium/QuoteReservation/action'
import { PostProposalSimulationsRequest } from 'store/modules/Consortium/ProposalSimulations/action'
import { ClientInformationPRORequest } from 'store/modules/Consortium/ClientPROInformation/action'

import ChooseState from './ChooseState'
import ProposalSkeleton from './ProposalSkeleton'
import ContemplatedValue from './ContemplatedValue'

import { IFormConsortium, Steps } from '../TypesPartnerOffer'
import { INITIAL_STATE_FORM } from '../TypesPartnerOffer/Form/initialState'
import { IBase64DecodedResponse } from '../ContractData/types'

import * as s from './styles'

const SimulationStep: StepComponent = () => {
  const { hash } = useParams<{ hash: string }>()
  const { hashDecoded } = useHashBase64Decoder<IBase64DecodedResponse>(hash)
  const { pro, menuPro } = TextsConsortium()
  const { addToast } = useToast()
  const { form, updateForm } = useFormFluxManagement<IFormConsortium>()
  const dispatch = useDispatch()
  const { instantiate } = useStackFluxManagement<StepComponentProps>()
  const [selectedState, setSelectedState] = React.useState<TSelected>({
    id: 0,
    name: '',
  })
  const [states, setStates] = useState<TOptions[]>([])
  const [uf, setUf] = useState<string>('')
  const [isLoadingButtonHire, setIsLoadingButtonHire] = useState<boolean>(false)
  const [contemplatedValue, setContemplatedValue] = useState({
    value: '',
    touched: false,
  })
  const [isFormPristine, setIsFormPristine] = useState<boolean>(true)

  useEffect(() => {
    if (hashDecoded) {
      dispatch(ClientInformationPRORequest({ cpf: hashDecoded.cpfCliente }))
    }
  }, [dispatch, hashDecoded])

  useEffect(() => {
    if (!form.proposal) {
      updateForm(INITIAL_STATE_FORM)
    }
  }, [form, updateForm])

  const simulationDispatch = React.useCallback(() => {
    if (selectedState && contemplatedValue) {
      dispatch(
        PostProposalSimulationsRequest({
          payload: {
            tipo_pessoa: 1,
            tipo_busca: 3,
            codigo_produto: 1,
            valor_credito:
              Number(contemplatedValue.value.replace(/\D/g, '')) / 100,
            uf,
            codigo_canal: 7,
          },
          onErrorToast: addToast,
          onSuccess: () => null,
        })
      )

      updateForm({
        simulation: {
          tipo_pessoa: 1,
          tipo_busca: 3,
          codigo_produto: 1,
          valor_credito:
            Number(contemplatedValue.value.replace(/\D/g, '')) / 100,
          codigo_canal: 7,
          uf,
        },
        proposal: { ...form.proposal },
      })
    }
  }, [
    form,
    addToast,
    contemplatedValue,
    dispatch,
    selectedState,
    uf,
    updateForm,
  ])

  const {
    data: consortiumProposalSimulations,
    isLoading,
    hasError,
  } = useSelector(
    (state: ApplicationState) => state.consortiumProposalSimulations
  )

  const { cpf } = useSelector((state: ApplicationState) => ({
    cpf: state.auth.cpf,
  }))

  const quoteReservation = useCallback(
    (simulation: any) => {
      const payload: IQuoteReservation = {
        codigo_produto: 1,
        representante: 604377,
        codigo_grupo: simulation.codigo_grupo,
        numero_cota: simulation.numero_cota,
        uf: form.simulation.uf,
        prazo: simulation.prazo,
        valor_credito: simulation.valor_credito,
        valor_parcela: simulation.valor_parcela,
        fundo_reserva: simulation.fundo_reserva,
        taxa_administrativa: simulation.taxa_administrativa,
        seguro_vida: simulation.seguro_vida,
        codigo_bem: '',
        informacao_produto: simulation.informacao_produto,
        assemb_participacao: simulation.assemb_participacao,
        dt_assembleia: simulation.dt_assembleia,
        dt_avencto_assembl: simulation.dt_avencto_assembl,
        total_vagas: simulation.total_vagas,
        taxa_adesao: simulation.taxa_adesao,
        taxa_administracao: simulation.taxa_administracao,
        tipo_lance: simulation.tipos_de_lance,
        desc_produto: '',
        fd_reserva: simulation.fd_reserva,
        tipo_grupo: simulation.tipo_grupo,
        valor_primeira_parcela: simulation.valor_primeira_parcela,
        valor_demais_parcelas: simulation.valor_demais_parcelas,
        valor_adesao: 0,
        numero_participantes: simulation.numero_participantes,
        percentual_lance_fixo: simulation.percentual_lance_fixo,
        percentual_lance_livre: simulation.percentual_lance_livre,
        percentual_lance_embutido: simulation.percentual_lance_embutido,
        cpf_cliente: cpf,
      }
      dispatch(
        PostQuotesRequest({
          payload,
          onError: () => {
            addToast({
              type: 'error',
              title: 'Algo inesperado aconteceu',
              description: 'Tente novamente dentro de alguns instantes.',
            })
          },
        })
      )
    },
    [addToast, cpf, dispatch, form.simulation]
  )

  const handlerClick = useCallback(
    (simulation: Simulation) => {
      updateForm({
        ...form,
        proposal: {
          ...form.proposal,
          assemb_participacao: simulation.assemb_participacao,
          codigo_bem: '',
          codigo_canal: form.simulation.codigo_canal,
          codigo_produto: 'IMOVEL',
          cota: simulation.numero_cota,
          credito_escolhido: simulation.valor_credito,
          data_primeiro_pagamento: simulation.dt_avencto_assembl,
          desc_produto: '',
          dt_assembleia: simulation.dt_assembleia,
          dt_venc_assembl: simulation.dt_avencto_assembl,
          fundo_reserva: simulation.fundo_reserva,
          grupo: simulation.codigo_grupo,
          informacao_produto: simulation.informacao_produto,
          numero_parcelas_escolhida: simulation.prazo,
          numero_participantes: simulation.numero_participantes,
          numero_sequencia_proposta: form.quoteReservation || 0,
          parcela_escolhida: simulation.valor_demais_parcelas,
          percentual_lance_embutido: simulation.percentual_lance_embutido,
          percentual_lance_fixo: simulation.percentual_lance_fixo,
          percentual_lance_livre: simulation.percentual_lance_livre,
          prazo: simulation.prazo,
          produto_sugerido: simulation.produto_sugerido,
          representante: 604377,
          seguro_vida: simulation.seguro_vida,
          taxa_adesao: simulation.taxa_adesao,
          taxa_administrativa: simulation.taxa_administrativa,
          tipo_consorcio: TIPO_CONSORCIO.IMOVEL,
          tipo_grupo: simulation.tipo_grupo,
          tipo_lance: simulation.tipos_de_lance,
          total_vagas: String(simulation.total_vagas),
          valor_credito: simulation.valor_credito,
          valor_demais_parcelas: simulation.valor_demais_parcelas,
          valor_primeira_parcela: simulation.valor_primeira_parcela,
        },
      })

      quoteReservation(simulation)

      instantiate(Steps.CONTRACT_PARTNER_PRO)
    },
    [instantiate, form, updateForm, quoteReservation]
  )

  const renderContent = () => {
    if (!isLoading && !consortiumProposalSimulations && !hasError) {
      return <s.HintText>{pro.simulation.hintText}</s.HintText>
    }
    if (hasError) {
      return <s.ErrorText>{pro.simulation.errorText}</s.ErrorText>
    }
    if (
      !isLoading &&
      consortiumProposalSimulations &&
      consortiumProposalSimulations.length > 0
    ) {
      return (
        <s.CardsWrapper
          cardsQuantity={consortiumProposalSimulations.length || 1}
        >
          {consortiumProposalSimulations.map((simulation: Simulation) => (
            <s.PlanCard
              creditValue={simulation.valor_credito}
              groupState={
                simulation.tipo_grupo === 'EM_ANDAMENTO'
                  ? 'inProgress'
                  : 'inFormation'
              }
              monthlyValue={simulation.valor_parcela}
              numberInstallment={simulation.prazo}
              groupCode={simulation.codigo_grupo}
              quota={simulation.numero_cota}
              reserveFund={simulation.fundo_reserva}
              administrativeFee={simulation.taxa_administrativa}
              lifeInsurance={simulation.seguro_vida}
              key={`${simulation.codigo_grupo}-${simulation.numero_cota}`}
              handlerClick={(isLoadingHandler: boolean) => {
                setIsLoadingButtonHire(isLoadingHandler)
                handlerClick(simulation)
              }}
              isLoading={isLoadingButtonHire}
            />
          ))}
        </s.CardsWrapper>
      )
    }

    if (
      !isLoading &&
      consortiumProposalSimulations &&
      consortiumProposalSimulations.length === 0
    ) {
      return <s.HintText>{pro.simulation.emptySimulations}</s.HintText>
    }

    if (isLoading) {
      return (
        <s.SkeletonRow
          css={`
            margin-top: 14px;
          `}
        >
          <s.Col lg={6}>
            <ProposalSkeleton />
          </s.Col>
          <s.Col lg={6}>
            <ProposalSkeleton />
          </s.Col>
        </s.SkeletonRow>
      )
    }

    return null
  }

  return (
    <>
      <StepBar
        title="Proposta"
        type="Consórcio de Imóvel"
        actualStep={2}
        totalNumberOfSteps={3}
      />
      <s.Container>
        <s.Col lg={3}>
          <s.SideWrapper>
            <s.SelectPlanText type="heading4" bold color="gray6">
              {pro.simulation.sideText.title}
            </s.SelectPlanText>
            <s.Text type="light" color="gray6">
              {pro.simulation.sideText.description}
            </s.Text>
            <s.Divisor />
            <s.SendProposalText
              type="subheading"
              textTransform="uppercase"
              bold
              color="gray5"
            >
              {menuPro.senderProposal}
            </s.SendProposalText>

            <UserCard name={hashDecoded?.nomeCliente || ''} type="cliente" />
          </s.SideWrapper>
        </s.Col>
        <s.Col lg={8}>
          <s.Card>
            <s.CardContainer>
              <s.Text
                type="subheading"
                bold
                color="gray5"
                textTransform="uppercase"
              >
                {pro.simulation.cardTitle}
              </s.Text>
              <ChooseState
                statesState={[states, setStates]}
                selectedStateState={[selectedState, setSelectedState]}
                ufState={[uf, setUf]}
              />
              <ContemplatedValue
                contemplatedValueState={[
                  contemplatedValue,
                  setContemplatedValue,
                ]}
              />
              <s.FindProposalButton
                disabled={
                  !selectedState.name ||
                  +contemplatedValue.value.replace(/\D/gm, '') / 100 < 50000
                }
                onClick={() => {
                  setIsFormPristine(false)
                  simulationDispatch()
                }}
                loading={isLoading}
              >
                {isFormPristine ? `carregar planos` : 'atualizar'}
              </s.FindProposalButton>
            </s.CardContainer>
          </s.Card>
          <s.ContentWrapper>{renderContent()}</s.ContentWrapper>
        </s.Col>
      </s.Container>
    </>
  )
}

export default SimulationStep
