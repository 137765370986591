import React, { useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  StepBar,
  Status,
  UserCard,
  Footer,
  LayerPRO,
} from 'pages/Consortium/SolicitationProcess/PRO/commons'
import useHashBase64Decoder from 'hooks/HashBase64Decoder'
import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'
import { ISendProposalConsortiumState } from 'store/modules/Consortium/SendProposal/types'
import { button, label, title } from './static'
import { user, status } from './mock'
import { IFormConsortiumPRO } from '../PartnerOffer/SimulationStep/types'
import { IBase64DecodedResponse } from '../PartnerOffer/ContractData/types'
import * as S from './styles'

const RequestResult: React.FC = () => {
  const history = useHistory()
  const { hash } = useParams<{ hash: string }>()
  const { hashDecoded } = useHashBase64Decoder<IBase64DecodedResponse>(hash)
  const { resetForm } = useFormFluxManagement<IFormConsortiumPRO>()

  const goStart = useCallback(() => {
    resetForm()
    history.push('/consorcio-imovel/pro/simular')
  }, [resetForm, history])

  const { hasError } = useSelector(
    (state: { consortiumSendProposal: ISendProposalConsortiumState }) =>
      state.consortiumSendProposal
  )

  return (
    <LayerPRO>
      <StepBar title={title} showSteps={false} />
      <S.Container>
        {hasError ? (
          <S.SuccessContainer>
            <Status status={status} />
            <S.ContainerUser>
              <S.Text {...S.TextProps}>{label}</S.Text>
              <UserCard
                name={hashDecoded?.nomeCliente || ''}
                type={user.type}
              />
            </S.ContainerUser>
          </S.SuccessContainer>
        ) : (
          <S.FailContainer>
            <Status status="fail" />
          </S.FailContainer>
        )}
      </S.Container>
      <Footer>
        <S.Button onClick={goStart} outline>
          {button.start}
        </S.Button>
      </Footer>
    </LayerPRO>
  )
}

export default RequestResult
