import React from 'react'
import { useSelector } from 'react-redux'

import Divisor from 'components/Divisor'
import { TOptions, TSelected } from 'components/Inputs/Select/types'

import { ApplicationState } from 'store/ApplicationState'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { IPaymentTermsInstallment } from 'store/modules/Consortium/PaymentTerms/types'

import { formatMoney } from 'helpers'
import { getDataBank } from './utils'
import { IPaymentData } from '../types'

import * as s from './styles'

const PaymentData: React.FC<IPaymentData> = ({
  paymentData: [paymentData, setPaymentData],
  content,
  amountOtherInstallments,
  isDrawer = false,
}: IPaymentData) => {
  const { formDefault } = TextsConsortium()

  const { data: paymentTermsonsortiumData } = useSelector(
    (state: ApplicationState) => state.consortiumPaymentTerms
  )

  const { data: bankConsortiumData } = useSelector(
    (state: ApplicationState) => state.consortiumBank
  )

  const [infoRate, setInfoRate] = React.useState<IPaymentTermsInstallment[]>()

  const [bankSelect, setBankSelect] = React.useState<TSelected>({
    id: 0,
    name: paymentData.bank || '',
  })

  const [banksOption, setBankOption] = React.useState<TOptions[]>([])

  const [rateSelect, setRateSelect] = React.useState<TSelected>({
    id: 0,
    name: '',
  })

  const [ratesOption, setRatesOption] = React.useState<TOptions[]>([])

  React.useEffect(() => {
    if (paymentTermsonsortiumData && paymentTermsonsortiumData.length) {
      setRatesOption(
        paymentTermsonsortiumData.map((value, id) => {
          if (value.codigo === paymentData.earlyAdministrationFree.codigo) {
            setRateSelect({
              id: value.codigo,
              name:
                value.descricao[0].toUpperCase() +
                value.descricao.substr(1).toLocaleLowerCase(),
            })
          }

          return {
            id,
            text:
              value.descricao[0].toUpperCase() +
              value.descricao.substr(1).toLocaleLowerCase(),
            onClick: () => {
              setRateSelect({
                id,
                name:
                  value.descricao[0].toUpperCase() +
                  value.descricao.substr(1).toLocaleLowerCase(),
              })
              setPaymentData({
                ...paymentData,
                earlyAdministrationFree: {
                  parcelas: value.parcelas,
                  codigo: value.codigo,
                  descricao: value.descricao,
                  nome: value.nome,
                  tipoCliente: value.tipoCliente,
                },
              })
              setInfoRate(value.parcelas)
            },
          }
        })
      )
    }
  }, [
    paymentData,
    paymentTermsonsortiumData,
    setPaymentData,
    setInfoRate,
    setRateSelect,
  ])

  React.useEffect(() => {
    const bankOption = getDataBank(
      bankConsortiumData,
      setBankSelect,
      (bank: string, code: string) => {
        if (bank) {
          setPaymentData({
            ...paymentData,
            bank,
            code,
          })
        }
      }
    )

    if (bankOption) {
      setBankOption(bankOption)
    }
  }, [bankConsortiumData, paymentData, setPaymentData])

  return (
    <s.WrapperPaymentData isDrawer={isDrawer}>
      <s.TitleText bold type="headline">
        {content.subtitle} {formatMoney(amountOtherInstallments)}
      </s.TitleText>
      <s.RowPaymentData isDrawer={isDrawer}>
        <s.ColPaymentData col={12}>
          <s.SelectPaymentData
            data-testid="select-bank-id"
            label={content.label.bank}
            options={banksOption}
            selected={bankSelect}
            optionBoxSize={300}
          />
        </s.ColPaymentData>
        <s.InputsContainer isDrawer={isDrawer}>
          <s.ColPaymentData sm={isDrawer ? 12 : 5}>
            <s.InputPaymentData
              data-testid="select-bank-id"
              label={content.label.agency}
              value={paymentData.agency}
              placeholder={formDefault.placeholderInput}
              maxLength={6}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setPaymentData({
                  ...paymentData,
                  agency: e.currentTarget.value.replace('-', ''),
                })
              }}
            />
          </s.ColPaymentData>
          <s.ColPaymentData sm={isDrawer ? 12 : 5}>
            <s.InputPaymentData
              data-testid="select-bank-id"
              label={content.label.currentAccount}
              value={paymentData.currentAccount}
              maxLength={9}
              placeholder={formDefault.placeholderInput}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setPaymentData({
                  ...paymentData,
                  currentAccount: e.currentTarget.value,
                })
              }}
            />
          </s.ColPaymentData>
          <s.ColPaymentData sm={isDrawer ? 12 : 2}>
            <s.InputPaymentData
              data-testid="select-bank-id"
              label={content.label.digit}
              value={paymentData.digit}
              maxLength={1}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setPaymentData({
                  ...paymentData,
                  digit: e.currentTarget.value,
                })
              }}
            />
          </s.ColPaymentData>
        </s.InputsContainer>
      </s.RowPaymentData>
      <s.DivisorPaymentData />
      <s.TitleText bold color="gray6" type="headline">
        {content.rate.title}
      </s.TitleText>
      <s.TitleText type="body">{content.rate.subtitle}</s.TitleText>
      <s.WrapperRateSelect>
        <s.SelectRatePaymentData
          data-testid="select-rate-id"
          label={content.rate.label}
          options={ratesOption}
          selected={rateSelect}
          optionBoxSize={300}
          optionBoxDirection="top"
        />
        {infoRate && infoRate.length > 0 ? (
          <s.WrapperInfoRate>
            {infoRate.slice(0, 2).map((value, index) => (
              <s.ContentInfoRate key={index}>
                <s.DataDisplay
                  label={value.descricao}
                  value={formatMoney(value.valor)}
                />
                {index < 1 ? <Divisor /> : null}
              </s.ContentInfoRate>
            ))}
          </s.WrapperInfoRate>
        ) : null}
      </s.WrapperRateSelect>
    </s.WrapperPaymentData>
  )
}

export default PaymentData
